export default {
  cake: {
    56: '0x0db5ca5Fd5E07E4038273CF5710E06fFcD0fecC0',
    97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
  },
  masterChef: {
    56: '0x7cd0dDf6f8aa136f34590b454FD7390341A34D7E',
    97: '0x69732418b7CCAAfE0bD42d37A0e66A788b93DA9c',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  usdt: {
    56: "0x55d398326f99059fF775485246999027B3197955"
  },
  tusd:{
    56:"0x14016E85a25aeb13065688cAFB43044C2ef86784"
  }
}
