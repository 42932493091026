import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [

  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'SIMEX',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x6712F1B1443B4513F230BA6eeb53527ea946f7f1', // AUTO-BNB LP
    },
    tokenSymbol: 'SIMEX',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x0db5ca5Fd5E07E4038273CF5710E06fFcD0fecC0',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE', // AUTO-BNB LP
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 4,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'TUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xE1c4f0CcFED718C9E11Bd70977381F1e8D13C00D', // AUTO-BNB LP
    },
    tokenSymbol: 'TUSD',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 5,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SIMEX-USDT LP',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x9cB9Fc426B25649b7d3Fb6207e115f5732252f2F', // AUTO-BNB LP
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 6,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SIMEX-TUSD LP',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x543901790225270A70784deb4bD2Cf5Cca75B57F', // AUTO-BNB LP
    },
    tokenSymbol: 'TUSD',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
    },
    quoteTokenSymbol: QuoteToken.TUSD,
    quoteTokenAdresses: contracts.tusd,
  },
  {
    pid: 7,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SIMEX-BNB LP',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x6712F1B1443B4513F230BA6eeb53527ea946f7f1', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 8,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SIMEX-BUSD LP',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x009cD9fdbBc4C3A8DbBb1B6a22bA7Da32d7FDF90', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 9,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SIMEX-BSW LP',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x70c406f9841ad26874E17FfC3F38269837e36453', // AUTO-BNB LP
    },
    tokenSymbol: 'SIMEX',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x0db5ca5Fd5E07E4038273CF5710E06fFcD0fecC0',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 10,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SIMEX-CHESS LP',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x86fA23b3579f986aD005E25988e696A467228105', // AUTO-BNB LP
    },
    tokenSymbol: 'SIMEX',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x0db5ca5Fd5E07E4038273CF5710E06fFcD0fecC0',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 11,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SIMEX-BABYDOGE LP',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x145cE3283FED0234fEb251109B5Ce0Afa8d3A7Be', // AUTO-BNB LP
    },
    tokenSymbol: 'SIMEX',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x0db5ca5Fd5E07E4038273CF5710E06fFcD0fecC0',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
]




export default farms
